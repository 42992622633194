import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Img from "@components/FileServer/Img";

import { AppState } from "@shared/data/reducers";

const EXPORT_HTML_PACKAGE = Boolean(process.env.NEXT_PUBLIC_EXPORT_PACKAGE);

type Props = {
  decreaseTolerance: () => void;
  increaseTolerance: () => void;
  toleranceDays: number;
};

const FilterBarDayTolerance = ({
  decreaseTolerance,
  increaseTolerance,
  toleranceDays,
}: Props) => {
  const { maxPlusMinusDay } = useSelector((state: AppState) => ({
    maxPlusMinusDay:
      state.storage.frontendSettings.dealerFlexShoppingSettings.maxPlusMinusDay,
  }));

  const wrapperClasses = classnames(
    "header-search-form-additional-desktop-one header-search-form-additional-desktop-one-tolerance no-border"
  );

  const counterSignMinusClass = classnames("counter-sign", {
    "counter-sign-disabled":
      (EXPORT_HTML_PACKAGE ? Number(maxPlusMinusDay) : toleranceDays) <= 0,
  });

  const counterSignPlusClass = classnames("counter-sign", {
    "counter-sign-disabled": toleranceDays >= parseInt(maxPlusMinusDay, 10),
  });

  return (
    <div className={wrapperClasses} id="GOL_package-variableDays">
      <div className="header-search-form-additional-desktop-one-tolerance-inner">
        <div className="header-search-form-additional-desktop-one-tolerance-inner-label">
          <FormattedMessage id="SearchForm.tolerance" />:
        </div>
        <div className="header-search-form-additional-bottom-tolerance-buttons">
          <div className="minus-button tolerance-item">
            <Img
              className={counterSignMinusClass}
              src="/static/images/ico-minus.svg"
              onClick={decreaseTolerance}
              id="GOL_package-variableDays-minus"
              alt="minus icon"
            />
          </div>
          <div className="tolerance-item">
            {EXPORT_HTML_PACKAGE ? (
              <span id="GOL_package-variableDays-value" />
            ) : (
              <FormattedMessage
                id="FilterBar.days"
                values={{ days: toleranceDays }}
              />
            )}
          </div>
          <div className="plus-button tolerance-item">
            <Img
              className={counterSignPlusClass}
              src="/static/images/ico-plus.svg"
              onClick={increaseTolerance}
              id="GOL_package-variableDays-plus"
              alt="plus icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBarDayTolerance;
