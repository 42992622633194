import Tooltip from "rc-tooltip";
import React, { forwardRef, useCallback, useState } from "react";
import { IntlShape, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { components } from "react-select";
import AsyncSelect from "react-select/async";

import { addAirportDestinations } from "shared/data/actions/destinations";
import { AppState } from "shared/data/reducers";
import destinationSearch from "shared/gol-api/destinationSearch";
import { AirportSelectValue } from "shared/lib/golObjectTypes/SelectObjects";
import { isFalsy } from "shared/lib/isFalsy";
import { formattedMessageParams } from "shared/messages";

import Img from "@components/FileServer/Img";
import { formatCityLabel } from "@components/SearchForm/formatCityLabel";
import FormattedMessage from "@components/UI/FormattedMessage";

const { airportPlaceholder, noAirportsFound, fillInPlaces } = defineMessages({
  airportPlaceholder: formattedMessageParams("AirportSelect.placeholder"),
  noAirportsFound: formattedMessageParams("AirportSelect.noAirportsFound"),
  fillInPlaces: formattedMessageParams("SearchForm.fillInPlaces"),
});

const TAB_KEY_CODE = 9;
const MIN_NUMBER_CHARACTERS_TO_START_SEARCH = 3;

interface Props {
  testId: string;
  idJumToAfterTab?: string;
  changeValue: (option: any) => void;
  defaultAirportSuggestions: AirportSelectValue[];
  oCountries: any;
  allowDefaultOptions: boolean;
  defaultValue?: AirportSelectValue;
  error?: any;
  intl: IntlShape;
  inputRef?: React.Ref<any>;
  dispatch?: any;
}

const CustomInput = forwardRef((inputProps: any, ref) => (
  <components.Input
    {...inputProps}
    ref={ref}
    data-cy={inputProps.selectProps.testId}
    className="r3"
    id={`airport-select-input-${inputProps.selectProps.testId}`}
  />
));

interface OptionProps {
  children: any;
  data: any;
  isFocused: boolean;
  isSelected: boolean;
  selectOption: (string) => void;
  oCountries: any;
  intl: IntlShape;
}

const categoryIcon = (category, hasMoreAirports) => {
  if (!["AIRPORT", "RAIL", "BUS"].includes(category)) {
    return null;
  }
  const categoryIconMapping = {
    AIRPORT: "plane",
    RAIL: "train",
    BUS: "bus",
  };
  return (
    <div
      style={{
        display: "inline-block",
        float: "left",
        marginLeft: hasMoreAirports ? "20px" : "0p",
      }}
    >
      <Img
        src={`/static/images/ico-${categoryIconMapping[category]}.svg`}
        style={{
          width: "14px",
          height: "14px",
          marginRight: "10px",
        }}
        alt="category icon"
      />
    </div>
  );
};

const OptionComponent = (props: OptionProps) => {
  const { data } = props;
  const showCode = isFalsy(data.ShowCode) ? data.ShowCode : true;

  return (
    <div
      role="button"
      className={`header-search-form-option ${
        props.isFocused || props.isSelected
          ? "header-search-form-option-selected"
          : ""
      }`}
      onClick={() => props.selectOption(data)}
    >
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        {categoryIcon(data?.Category, data?.Parent)}
        <div>
          {data.label}
          {props?.oCountries[data.Country]
            ? `, ${props.oCountries[data.Country]}`
            : ""}
          {showCode && <b> ({data.value})</b>}
        </div>
        <a
          target="_blank"
          href={`https://www.google.com/maps/search/Airports+${encodeURIComponent(
            props.children
          )}`}
          style={{ zIndex: 2, marginLeft: "auto" }}
        >
          <Tooltip
            placement="top"
            mouseEnterDelay={0}
            mouseLeaveDelay={0.1}
            trigger={["hover"]}
            overlayClassName="tooltip-background"
            overlay={
              <div>
                <a
                  target="_blank"
                  href={`https://www.google.com/maps/search/Airports+${encodeURIComponent(
                    props.children
                  )}`}
                  style={{ zIndex: 2, color: "white", textDecoration: "none" }}
                >
                  <FormattedMessage id="AirportSelect.showDestination" />
                </a>
              </div>
            }
          >
            <Img
              id={`open-map-${props.children}`}
              src="/static/images/ico-pin.svg"
              style={{
                float: "right",
                zIndex: 12,
                position: "relative",
                cursor: "pointer",
                top: 3,
              }}
              alt="pin icon"
            />
          </Tooltip>
        </a>
      </div>
    </div>
  );
};

const AirportSelect = (props: Props) => {
  const {
    testId,
    idJumToAfterTab,
    changeValue,
    defaultAirportSuggestions,
    oCountries,
    allowDefaultOptions,
    defaultValue,
    error,
    intl,
    inputRef,
    dispatch,
  } = props;

  const [noOptionsMessage, setNoOptionsMessage] = useState(false);

  const onInput = useCallback(
    async (inputValue) => {
      if (inputValue.length < MIN_NUMBER_CHARACTERS_TO_START_SEARCH) {
        setNoOptionsMessage(false);
        return [];
      }

      const suggestions = await destinationSearch(inputValue, "flight");

      if (dispatch) {
        dispatch(addAirportDestinations([...suggestions]));
      }

      setNoOptionsMessage(true);

      return suggestions.map((oSuggestion) => ({
        value: oSuggestion.Code,
        label: oSuggestion.$t,
        Country: oSuggestion.Country,
        Category: oSuggestion.Category,
        Parent: oSuggestion.Parent,
        ShowCode: isFalsy(oSuggestion.ShowCode) ? oSuggestion.ShowCode : true,
      }));
    },
    [dispatch, setNoOptionsMessage]
  );

  const SingleValue = useCallback(
    (singleValueProps) => {
      const label = formatCityLabel(singleValueProps.data.label);

      const showCode = isFalsy(singleValueProps.data.ShowCode)
        ? singleValueProps.data.ShowCode
        : true;

      if (!label) {
        return null;
      }

      return (
        <span
          role="button"
          onClick={() => singleValueProps.clearValue()}
          id={`${testId}-value`}
        >
          <span className="header-search-form-inner-field-value">{label}</span>
          {showCode && (
            <span className="header-search-form-inner-field-additional">
              {` (${singleValueProps.data.value})`}
            </span>
          )}
        </span>
      );
    },
    [testId]
  );

  const SelectContainer = useCallback(
    (containerProps) => (
      <components.SelectContainer
        {...containerProps}
        className="react-select-2-wrapper-2"
      />
    ),
    []
  );

  const customMenuContainer = useCallback(
    (menuProps) => {
      if (
        menuProps.selectProps.inputValue.length === 0 &&
        !allowDefaultOptions
      ) {
        return null;
      }

      return <components.Menu {...menuProps} className="select-menu-outer" />;
    },
    [allowDefaultOptions]
  );

  const onChange = useCallback(
    (option) => {
      if (option !== undefined && option !== null) {
        changeValue(option);
      } else {
        changeValue(null);
      }
    },
    [changeValue]
  );

  const onKeyDown = useCallback(
    (e) => {
      setNoOptionsMessage(true);

      if (!idJumToAfterTab) return;

      if (e.keyCode === TAB_KEY_CODE) {
        document
          .getElementById(`airport-select-input-${idJumToAfterTab}`)
          ?.focus();
      }
    },
    [idJumToAfterTab, setNoOptionsMessage]
  );

  const Option = useCallback(
    (val) => (
      <OptionComponent
        oCountries={oCountries}
        {...val}
        className="r5"
        intl={intl}
      />
    ),
    [oCountries, intl]
  );

  const Placeholder = useCallback((placeholderProps) => {
    if (placeholderProps.isFocused) return null;
    return (
      <components.Placeholder
        {...placeholderProps}
        className="react-select-2-placeholder"
      />
    );
  }, []);

  return (
    <div className="header-search-form-results">
      <AsyncSelect
        onKeyDown={onKeyDown}
        testId={testId}
        ref={inputRef}
        placeholder={intl.formatMessage(airportPlaceholder)}
        className="react-select-2-wrapper"
        id={`airport-select-${testId}`}
        styles={{
          input: (base) => ({
            ...base,
            display: "inline-block",
          }),
          control: () => ({}),
          valueContainer: (base) => ({
            ...base,
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: -4,
            display: "inline-block",
            height: "max-content",
          }),
          placeholder: () => ({
            marginTop: -1,
            boxSizing: "border-box",
            label: "placeholder",
            display: "inline-block",
          }),
          menuList: (base) => ({
            ...base,
            maxHeight: "200px",
            paddingTop: 0,
            paddingBottom: 0,
          }),
          noOptionsMessage: () => ({
            marginLeft: "15px",
            fontFamily: "Muli",
            padding: "10px 0px 10px 0px",
            display: !noOptionsMessage ? "none" : "auto",
          }),
        }}
        isClearable
        value={defaultValue}
        noOptionsMessage={() => intl.formatMessage(noAirportsFound)}
        components={{
          DropdownIndicator: () => null,
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
          SingleValue,
          Menu: customMenuContainer,
          SelectContainer,
          Input: CustomInput,
          Option,
          Placeholder,
        }}
        onChange={onChange}
        loadOptions={onInput}
        defaultOptions={
          allowDefaultOptions
            ? defaultAirportSuggestions.map((oSuggestion) => ({
                value: oSuggestion.Code,
                label: oSuggestion.label,
                Country: oSuggestion.Country,
                ShowCode: oSuggestion.ShowCode,
              }))
            : null
        }
        cacheOptions
      />
      {error ? (
        <div className="search-field-error">
          {intl.formatMessage(fillInPlaces)}
        </div>
      ) : null}
    </div>
  );
};

export default connect((state: AppState) => ({
  oCountries: state.storage.countries,
  defaultAirportSuggestions: state.storage.defaultAirportSuggestions,
}))(AirportSelect);
