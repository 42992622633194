import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

function getGolRequestDestinationSearchQuery(
  searchDestinationPattern: string,
  searchType?: string
) {
  const searchTypeValue = searchType || "flight";
  const golRequest = {
    GolApi: {
      RequestDetail: {
        SearchDestinationsRequest_1: {
          SearchPattern: {
            $t: searchDestinationPattern,
            SearchType: searchTypeValue,
          },
        },
      },
    },
  };

  return golRequest;
}

function mapSearchResultsForAutosuggest(searchDestinationResponse, codebook) {
  const airportSuggestions =
    searchDestinationResponse.SearchedAirports.SearchedAirport;

  return airportSuggestions.map((oSearchedAirport) => {
    const oAirportCodeBook = codebook.Airports.Airport.find(
      (oAirport) => oAirport.Code === oSearchedAirport.Destination
    );
    return {
      Code: oSearchedAirport.Destination,
      Parent: oSearchedAirport.Parent,
      Country: oAirportCodeBook.Country,
      State: oAirportCodeBook.State,
      $t: oAirportCodeBook.$t,
      Category: oAirportCodeBook.Category,
      ShowCode: oSearchedAirport.ShowCode
        ? oSearchedAirport.ShowCode === "true"
        : true,
    };
  });
}

export default async (
  searchDestinationPattern: string,
  searchType?: string
) => {
  const golRequest = getGolRequestDestinationSearchQuery(
    searchDestinationPattern,
    searchType
  );

  const searchDestinationResponse = await golApiCall(golRequest);
  const response = createResponse(searchDestinationResponse);

  if (!response.success) {
    return [];
  }

  return mapSearchResultsForAutosuggest(response.data, response.codebook);
};
