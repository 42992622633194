import classnames from "classnames";
import moment from "moment";
import Router, { SingletonRouter, useRouter, withRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { IntlShape, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getCbtToken } from "shared/cbt/cbt";
import { MODULES } from "shared/constants";
import {
  decrementGuests,
  incrementGuests,
  initPassengerForm,
} from "shared/data/actions/cbt";
import { getDestinationByCode } from "shared/data/actions/destinations";
import { setShouldMinimizeSearchForm } from "shared/data/actions/headerSearchForm";
import { setSearchQuery } from "shared/data/actions/storage";
import { AppState } from "shared/data/reducers";
import {
  AirportDestinations,
  DefaultAirportSuggestions,
  SearchQuery,
} from "shared/data/reducers/types/storage";
import { searchFlightBase } from "shared/lib/constants";
import {
  countPassengers,
  getBasicDate,
  isEmptyObject,
  pad2,
} from "shared/lib/functions";
import { PassengersCount } from "shared/lib/golObjectTypes/PassengerTypes";
import {
  Airlines,
  AirportSelectValue,
} from "shared/lib/golObjectTypes/SelectObjects";
import {
  maxPlusMinusDays,
  plusMinusDaysEnabled,
} from "shared/lib/requestorFunctions";
import { AirportDestination, FrontendSettings } from "shared/types";

import Img from "@components/FileServer/Img";
import SearchFormAirportSelect from "@components/SearchForm/AirportSelect";
import DatePicker from "@components/SearchForm/Calendar/DatePicker";
import SearchFormHotelSelect from "@components/SearchForm/HotelSelect";
import MinimizedHeaderSearchForm from "@components/SearchForm/MinimizedHeaderSearchForm";
import PassengerPreferences, {
  cbtFormFilled,
} from "@components/SearchForm/PassengerPreferences";
import FormattedMessage from "@components/UI/FormattedMessage";

import { isIframe } from "@lib/iframe";
import { checkIsHotelSearchForm } from "@lib/webFunctions";
import { ROUTES } from "@shared/constants";

import DatesRow from "./DatesRow";
import FilterBar from "./FilterBar/FilterBar";
import FilterBarMobile from "./FilterBar/FilterBarMobile";

const EXPORT_HTML_PACKAGE = Boolean(process.env.NEXT_PUBLIC_EXPORT_PACKAGE);

interface HeaderSearchFormProps {
  frontendSettings: FrontendSettings;
  query?: SearchQuery;
  page?: string;
  intl?: IntlShape;
  defaultAirportSuggestions?: DefaultAirportSuggestions;
  destinations: AirportDestinations;
  departureDate?: string;
  departureDays?: number;
  durationOfStay?: number;
  currentLanguage?: string;
  router?: SingletonRouter;
  airlines?: Airlines;
  toleranceDays?: number;
  hotelsEnabled: boolean;
  anonymousSearchEnabled: boolean;
  searchQuery: SearchQuery;
  customerUsername?: string;
  carrierFilter: string[] | null;
  module: string[];
  allowedTravelerTypes: any;
  isCbtEnabled: boolean;
  isLoggedIn: boolean;
  user: any;
  defaultCountry: string;
  shouldMinimizeSearchForm: boolean;
}

interface SearchFlight {
  origin: any;
  destination: any;
  departureDate: string;
}

const getCorrectDepartureDaysNumber = (
  departureDays: number,
  blockSearchDaysBefore: number | undefined
): number => {
  if (Number.isNaN(blockSearchDaysBefore)) {
    return departureDays;
  }

  if (departureDays >= blockSearchDaysBefore) {
    return departureDays;
  }

  return blockSearchDaysBefore;
};

const HeaderSearchForm = (props: HeaderSearchFormProps) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const inputFromRef = useRef(null);
  const inputToRef = useRef(null);
  const {
    destinations,
    defaultAirportSuggestions,
    hotelsEnabled,
    frontendSettings,
    module,
    departureDays,
    durationOfStay,
    user,
    isLoggedIn,
    isCbtEnabled,
    cbtPassengersForm,
    searchQuery,
    anonymousSearchEnabled,
    customerUsername,
    cbt,
    airlines,
    allowedTravelerTypes,
    shouldMinimizeSearchForm,
    defaultCountry,
    currentLanguage,
    toleranceDaysConfig,
  } = useSelector((state: AppState) => ({
    destinations: state.storage.airportDestinations,
    defaultAirportSuggestions: state.storage.defaultAirportSuggestions,
    currentLanguage: state.requestorConfig.currentLanguage,
    hotelsEnabled: state.requestorConfig.hotelsEnabled,
    frontendSettings: state.storage.frontendSettings,
    module: state.requestorConfig.module,
    departureDays: getCorrectDepartureDaysNumber(
      state.storage.departureDays,
      parseInt(
        state.storage.frontendSettings?.dealerFlightSearchSettings
          ?.blockSearchDaysBefore,
        10
      )
    ),
    durationOfStay: state.storage.durationOfStay,
    airlines: state.storage.airlines,
    user: state.user,
    isLoggedIn: state.user.isLoggedIn,
    isCbtEnabled:
      state.storage.frontendSettings.dealerCorporateSettings?.enableCbt ===
      "true",
    cbtPassengersForm: state.cbt.passengersForm,
    searchQuery: state.storage.searchQuery,
    anonymousSearchEnabled:
      state.storage.frontendSettings?.dealerCorporateSettings
        ?.enableAnonymousSearch === "true",
    customerUsername: state.user.email,
    cbt: state.cbt,
    allowedTravelerTypes: state.requestorConfig.allowedTravelerTypes,
    carrierFilter: state.storage.frontendSettings.carrierFilter,
    shouldMinimizeSearchForm: state.headerSearchForm.shouldMinimizeSearchForm,
    defaultCountry:
      state.storage.frontendSettings?.dealerFrontendSettings?.defaultCountry ??
      "",
    toleranceDaysConfig:
      state.storage.frontendSettings.dealerFlexShoppingSettings.maxPlusMinusDay,
  }));

  const [typeSearch, setTypeSearch] = useState<string>("RETURN");
  const [toleranceDays, setToleranceDays] = useState<number>(
    Number(toleranceDaysConfig) || 0
  );
  const [directFlight, setDirectFlight] = useState<boolean>(false);
  const [
    selectedTransportCompany,
    setSelectedTransportCompany,
  ] = useState<string>("all");
  const [weekends, setWeekends] = useState<boolean>(false);
  const [from, setFrom] = useState<string | AirportSelectValue>(
    defaultAirportSuggestions[0]
      ? ({
          value: defaultAirportSuggestions[0].Code,
          label: defaultAirportSuggestions[0].label,
          Country: defaultAirportSuggestions[0].Country,
          ShowCode: defaultAirportSuggestions[0].ShowCode,
          Code: defaultAirportSuggestions[0].Code,
        } as AirportSelectValue)
      : ""
  );
  const [to, setTo] = useState<string | AirportSelectValue>(
    searchFlightBase.destination
  );
  const [departureDate, setDepartureDate] = useState<string>(
    getBasicDate(departureDays)
  );
  const [returnDate, setReturnDate] = useState<string>(
    getBasicDate(departureDays + durationOfStay)
  );
  const [searchFlights, setSearchFlights] = useState<SearchFlight[]>([
    {
      ...searchFlightBase,
      departureDate: getBasicDate(departureDays),
    },
    {
      ...searchFlightBase,
      departureDate: getBasicDate(departureDays + durationOfStay),
    },
  ]);
  const [passengers, setPassengers] = useState<PassengersCount>({});
  const [rooms, setRooms] = useState<number>(1);
  const [airlinesTouched, setAirlinesTouched] = useState<boolean>(false);
  const [flightClass, setFlightClass] = useState<string>("ECO");
  const [error, setError] = useState<{
    to: boolean;
    from: boolean;
    multiCity: Record<string, any>;
    cbtPassengersForm: boolean;
  }>({ to: false, from: false, multiCity: {}, cbtPassengersForm: false });

  const getInitialPassengersNumbersFromAllowedTravelerTypes = () => {
    const oNumberOfPassengers = {};

    const isLoggedCbtUser = isCbtEnabled && isLoggedIn;
    const isHotelSearchForm = checkIsHotelSearchForm(router);

    if (isLoggedCbtUser && !isHotelSearchForm) {
      cbt.grades.forEach((grade) => {
        if (!grade.EnableForGuests) {
          return;
        }

        const count = cbtPassengersForm.guestsGradeIds.filter(
          (id) => id === grade.Id
        ).length;

        oNumberOfPassengers[`${grade.Id}_${grade.PassengerTypeCode}`] =
          count || 0;
      });
    } else {
      allowedTravelerTypes.forEach((allowedTravelerType, index) => {
        oNumberOfPassengers[allowedTravelerType.Code] =
          index === 0 && !isLoggedCbtUser ? 1 : 0;
      });
    }

    return oNumberOfPassengers;
  };

  useEffect(() => {
    const { query } = router;

    const hotelModuleEnabled = module?.includes(MODULES.HOTEL);
    const flightsModuleEnabled = module?.includes(MODULES.AIR);

    setPassengers(getInitialPassengersNumbersFromAllowedTravelerTypes());

    if (!flightsModuleEnabled && hotelModuleEnabled) {
      changeTypeSearch("HOTELS");
    }

    if (isEmptyObject(searchQuery)) dispatch(setSearchQuery(query));
    if (
      router.asPath.includes(ROUTES.RESULTS) ||
      router.asPath.includes(ROUTES.RESULT) ||
      router.asPath.includes(ROUTES.HOTELS) ||
      router.asPath.includes(ROUTES.HOTEL) ||
      router.asPath.includes(ROUTES.BOOK_HOTEL)
    ) {
      parseQueryIntoSearchForm(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cbt.grades.length, router.asPath]);

  const findDestinationByCode = async (code) => {
    return dispatch(getDestinationByCode(code, destinations));
  };

  const defaultValues = async (query) => {
    if (query.from) {
      const fromVal: any = { value: query.from, label: "" };
      if (query.from.includes("/")) {
        const airportCodes = query.from.split("/");
        let label = "";
        (await Promise.all(airportCodes.map(findDestinationByCode))).forEach(
          (res: AirportDestination) => {
            fromVal.Country = res?.Country;
            fromVal.ShowCode = label?.ShowCode;

            label += ` / ${res?.$t}`;
          }
        );
        fromVal.label = label.slice(3);
        fromVal.Code = query.from;
      } else {
        const label = await findDestinationByCode(query.from);

        fromVal.Country = label?.Country;
        fromVal.label = label?.$t;
        fromVal.Code = query.from;
        fromVal.ShowCode = label?.ShowCode;
      }
      const newDepartureDate = Date.parse(query.departureDate)
        ? query.departureDate
        : departureDate;

      setFrom(fromVal);
      setDepartureDate(newDepartureDate);
    }

    if (query.to) {
      const label = await findDestinationByCode(query.to);

      const newReturnDate = Date.parse(query.returnDate)
        ? query.returnDate
        : returnDate;
      if (label) {
        const toVal: any = {
          value: query.to,
          label: label?.$t,
          Country: label?.Country,
          ShowCode: label?.ShowCode,
          Code: query.to,
        };
        setTo(toVal);
        setReturnDate(newReturnDate);
      }
    }
    if (query.toleranceDays) {
      setToleranceDays(Number(query.toleranceDays));
    }

    if (query.typeSearch === "hotels") {
      const label = await findDestinationByCode(query.destination);
      if (label) {
        const destination: any = {
          value: query.destination,
          label: label?.$t,
          Country: label?.Country,
          Code: query.destination,
        };
        setTo(destination);
      }
    }
  };

  const initCbtPassengerForm = (query) => {
    const { costCenterId, travelReasonId } = query;

    if (costCenterId && travelReasonId) {
      dispatch(initPassengerForm(query));
    }
  };

  const parseQueryIntoSearchForm = async (query) => {
    initCbtPassengerForm(query);

    if (query === undefined) {
      return;
    }

    const queryKeys = Object.keys(query).filter((param) =>
      param.includes("flight_")
    );
    let flightType;
    if (queryKeys.length > 0) {
      flightType = "MULTIPLE";
      const originAirports = [];
      const destinationAirports = [];
      const departureDates = [];
      queryKeys.forEach((el) => {
        if (el.includes("origin")) {
          originAirports.push(query[el]);
        } else if (el.includes("destination")) {
          destinationAirports.push(query[el]);
        } else if (el.includes("departureDate")) {
          departureDates.push(query[el]);
        } else {
          return false;
        }
      });
      await parseMultiCityFlight(
        originAirports,
        destinationAirports,
        departureDates
      );
    } else if (!query.returnDate) {
      flightType = "ONE_WAY";
      await defaultValues(query);
    } else {
      flightType = "RETURN";
      await defaultValues(query);
    }

    if (query.preferred_airline) {
      const airline = airlines.find(
        (al) => al.value === query.preferred_airline
      );
      if (airline) {
        selectAirline({ value: query.preferred_airline });
      }
    }

    setTypeSearch(flightType);
    if (query.max_transfers === "direct") {
      setDirectFlight(true);
    }
    const passengersData = getPassengers(query);
    setPassengers(passengersData.passengers);
    setDepartureDate(passengersData.departureDate);
    setReturnDate(passengersData.returnDate);
    if (query.flightClass) {
      setFlightClass(query.flightClass);
    }
  };

  const getPassengers = (query) => {
    const passengersResult = {};

    const isLoggedCbtUser = isCbtEnabled && isLoggedIn;

    if (isLoggedCbtUser && !checkIsHotelSearchForm(router)) {
      cbt.grades.forEach((grade) => {
        if (!grade.EnableForGuests) {
          return;
        }

        passengersResult[`${grade.Id}_${grade.PassengerTypeCode}`] =
          Number(query[`${grade.Id}_${grade.PassengerTypeCode}`]) || 0;
      });
    } else {
      allowedTravelerTypes.forEach((allowedTravelerType) => {
        passengersResult[allowedTravelerType.Code] = query[
          allowedTravelerType.Code
        ] // Use passengersResult
          ? Number(query[allowedTravelerType.Code])
          : 0;
      });
    }

    return {
      passengers: passengersResult,
      returnDate:
        query.returnDate ||
        query.flight_2_departureDate ||
        getBasicDate(departureDays + durationOfStay),
      departureDate:
        query.departureDate ||
        query.flight_1_departureDate ||
        getBasicDate(departureDays),
    };
  };

  const parseMultiCityFlight = async (
    originAirports,
    destinationAirports,
    departureDates
  ) => {
    const updatedSearchFlights = [...searchFlights];

    (await Promise.all(originAirports.map(parseMulti))).forEach(
      (airportObj, index) => {
        if (index < updatedSearchFlights.length) {
          // Added check
          updatedSearchFlights[index] = {
            ...updatedSearchFlights[index],
            origin: { ...airportObj },
            departureDate: departureDates[index],
          };
        }
      }
    );

    (await Promise.all(destinationAirports.map(parseMulti))).forEach(
      (airportObj, index) => {
        if (index < updatedSearchFlights.length) {
          updatedSearchFlights[index] = {
            ...updatedSearchFlights[index],
            destination: { ...airportObj },
          };
        }
      }
    );
    setSearchFlights(updatedSearchFlights);
  };

  const parseMulti = async (airport) => {
    const airportObj = {
      value: "",
      label: "",
      Parent: "",
      Code: "",
    };
    if (airport.includes("/")) {
      const airportCodes = airport.split("/");
      let combinedLabel = "";

      (await Promise.all(airportCodes.map(findDestinationByCode))).forEach(
        (res) => {
          combinedLabel += ` / ${res.$t}`;
        }
      );
      airportObj.label = combinedLabel.slice(3);
    } else {
      const res = await findDestinationByCode(airport);
      airportObj.Parent = res?.Parent;
      airportObj.label = res?.$t;
    }

    airportObj.value = airport;
    airportObj.Code = airport;

    return airportObj;
  };

  const changeTypeSearch = (typeSearchValue) => {
    // Changed parameter name
    if (["HOTELS", "FLIGHTS"].includes(typeSearchValue)) {
      // Use parameter
      let futureAsPath;

      if (router.pathname.includes("[")) {
        if (router.asPath.includes("?")) {
          if (router.asPath.includes("typeSearch=")) {
            futureAsPath = router.asPath
              .split(
                `typeSearch=${
                  typeof router.query.typeSearch === "string"
                    ? router.query.typeSearch.toLowerCase()
                    : ""
                }`
              ) // Use router.query.typeSearch
              .join(`typeSearch=${typeSearchValue.toLowerCase()}`);
          } else {
            futureAsPath = `${
              router.asPath
            }&typeSearch=${typeSearchValue.toLowerCase()}`;
          }
        } else {
          futureAsPath = `${
            router.asPath
          }?typeSearch=${typeSearchValue.toLowerCase()}`;
        }
      }

      return Router.push(
        {
          pathname: router.pathname,

          query: {
            ...Router.router.query,
            typeSearch: typeSearchValue === "HOTELS" ? "hotels" : "flights", // Use parameter
          },
        },
        futureAsPath
      );
    }

    if (typeSearch === "MULTIPLE") {
      setTypeSearch(typeSearchValue);
      setFrom(searchFlights[0].origin);
      setTo(searchFlights[0].destination);
    } else if (typeSearchValue === "MULTIPLE") {
      const updatedSearchFlights = [...searchFlights];
      updatedSearchFlights[0] = {
        ...searchFlights[0],
        origin: from,
        destination: to,
      };
      setTypeSearch(typeSearchValue);
      setSearchFlights(updatedSearchFlights);
    } else {
      setTypeSearch(typeSearchValue);
    }
  };

  const increaseTolerance = () => {
    if (weekends) {
      return;
    }

    if (toleranceDays < maxPlusMinusDays(frontendSettings)) {
      setToleranceDays(toleranceDays + 1);
    }
  };

  const decreaseTolerance = () => {
    if (weekends) {
      return;
    }
    if (toleranceDays > 0) {
      setToleranceDays(toleranceDays - 1);
    }
  };

  const formatPassengersForQuery = () => {
    return Object.keys(passengers).reduce((acc, passengerKey) => {
      if (passengers[passengerKey] !== 0) {
        acc[passengerKey] = passengers[passengerKey];
      }
      return acc;
    }, {});
  };

  const areInputsFilled = async (query) => {
    const { corporate } = cbt;

    const isHotelSearchForm = checkIsHotelSearchForm(router);
    const updatedError = { ...error };
    const allowGuestOnlyReservation = corporate?.AllowGuestOnlyReservation;

    updatedError.cbtPassengersForm =
      (await getCbtToken(user, frontendSettings, customerUsername)) &&
      !cbtFormFilled(cbtPassengersForm, allowGuestOnlyReservation);

    if (isHotelSearchForm) {
      if (to === "") {
        updatedError.to = true;
      }
      if (
        updatedError.from ||
        updatedError.to ||
        updatedError.cbtPassengersForm
      ) {
        setError({ ...updatedError });
        return false;
      }
    } else {
      switch (typeSearch) {
        case "ONE_WAY":
        case "RETURN":
          if (from === "") {
            updatedError.from = true;
          }
          if (to === "") {
            updatedError.to = true;
          }
          if (
            updatedError.from ||
            updatedError.to ||
            updatedError.cbtPassengersForm
          ) {
            setError({ ...updatedError });
            return false;
          }
          break;
        case "MULTIPLE":
          Object.keys(query).forEach((key) => {
            if (key.includes("flight_") && query[key].length === 0) {
              updatedError.multiCity = {
                ...updatedError.multiCity,
                [key]: true,
              };
            }
          });
          if (
            Object.values(updatedError.multiCity).length > 0 ||
            updatedError.cbtPassengersForm
          ) {
            setError({
              ...updatedError,
              multiCity: { ...updatedError.multiCity },
            });
            return false;
          }
          break;
        default:
          return false;
      }
    }

    return true;
  };

  const search = async () => {
    // @ts-ignore
    if (checkIsHotelSearchForm(router)) {
      return searchHotels();
    }
    return searchFlightsFc();
  };

  const searchFlightsFc = async () => {
    const cbtToken = await getCbtToken(
      user,
      frontendSettings,
      customerUsername
    );
    if (cbtToken && cbt?.users?.length === 0) {
      return;
    }
    const formattedPassengers = formatPassengersForQuery();

    const query: any = {
      from:
        (typeSearch !== "MULTIPLE"
          ? (from as AirportSelectValue)?.value
          : "") || Router.query.from,
      to:
        (typeSearch !== "MULTIPLE" ? (to as AirportSelectValue)?.value : "") ||
        Router.query.to,
      flightClass,
      departureDate: departureDate || Router.query.departureDate,
      returnDate:
        typeSearch === "RETURN" ? returnDate || Router.query.returnDate : "",
      ...formattedPassengers,
      date: Date.now(),
    };

    if (selectedTransportCompany !== "all") {
      query.preferred_airline = selectedTransportCompany;
    }
    if (directFlight) {
      query.max_transfers = "direct";
    }
    if (toleranceDays && typeSearch !== "MULTIPLE") {
      query.toleranceDays = toleranceDays;
    }
    if (Router.query.lang) {
      query.lang = Router.query.lang;
    }
    if (Router.query.currency) {
      query.currency = Router.query.currency;
    }

    if (typeSearch === "MULTIPLE") {
      delete query.from;
      delete query.to;
      delete query.departureDate;
      delete query.returnDate;

      searchFlights.forEach((oSearchFlight, i) => {
        query[`flight_${i + 1}_origin`] = oSearchFlight.origin
          ? (oSearchFlight.origin as AirportSelectValue).value
          : "";
        query[`flight_${i + 1}_destination`] = oSearchFlight.destination
          ? (oSearchFlight.destination as AirportSelectValue).value
          : "";
        query[`flight_${i + 1}_departureDate`] = oSearchFlight.departureDate;
      });
    }

    if (!(await areInputsFilled(query))) {
      return;
    }

    dispatch(setShouldMinimizeSearchForm(true));
    redirectToSearchResults({
      searchQueryParams: query,
      pathname: ROUTES.RESULTS,
    });
  };

  const searchHotels = async () => {
    const hotelSearchQuery: any = {
      INF: passengers.INF || 0,
      CHD: passengers.CHD || 0,
      ADT: passengers.ADT || 0,
      destination: (to as AirportSelectValue)?.value,
      departureDate: departureDate || Router.query.departureDate,
      returnDate: returnDate || Router.query.returnDate,
      typeSearch: "hotels",
    };

    if (Router.query.currency) {
      hotelSearchQuery.currency = Router.query.currency;
    }

    if (!(await areInputsFilled(hotelSearchQuery))) {
      return;
    }

    dispatch(setShouldMinimizeSearchForm(true));

    redirectToSearchResults({
      searchQueryParams: hotelSearchQuery,
      pathname: ROUTES.HOTELS,
    });
  };

  const redirectToSearchResults = ({ searchQueryParams, pathname }) => {
    const cbtParams = getCbtParams();

    const query = { ...searchQueryParams, ...cbtParams };

    if (isIframe(Router.router.route)) {
      const str = Object.keys(query)
        .map((key) => `${key}=${encodeURIComponent(query[key])}`)
        .join("&");

      try {
        top.window.location.href = `${window.location.origin}${pathname}?${str}`;
      } catch (e) {
        window.parent.postMessage(
          `${window.location.origin}${pathname}?${str}`,
          "*"
        );
      }
      return;
    }

    Router.push({
      pathname,
      query,
    });
  };

  const getCbtParams = () => {
    const { corporate } = cbt;
    const allowGuestOnlyReservation = corporate?.AllowGuestOnlyReservation;

    return getCbtToken(user, frontendSettings, customerUsername) &&
      cbtFormFilled(cbtPassengersForm, allowGuestOnlyReservation)
      ? {
          guestsGradeIds: cbtPassengersForm.guestsGradeIds,
          userIds: cbtPassengersForm.travellers,
          guests: cbtPassengersForm.guests,
          flightClass: cbtPassengersForm.flightClass,
          costCenterId: cbtPassengersForm.costCenter,
          travelReasonId: cbtPassengersForm.travelReason,
        }
      : {};
  };

  const plusCounterHandler = (type: string, id: string | null = null) => {
    if (type === "ROM") {
      setRooms(rooms + 1);
      return;
    }

    if (countPassengers(passengers) > 8) {
      return;
    }

    dispatch(incrementGuests(id));

    setPassengers((prevPassengers) => ({
      ...prevPassengers,
      [type]: (prevPassengers[type] || 0) + 1,
    }));
  };
  const minusCounterHandler = (type: string, id: string | null = null) => {
    if (type === "ROM") {
      if (rooms > 1) {
        setRooms(rooms - 1);
      }
      return;
    }

    if (!passengers[type] || passengers[type] < 1) {
      return;
    }

    dispatch(decrementGuests(id));

    setPassengers((prevPassengers) => ({
      ...prevPassengers,
      [type]: prevPassengers[type] - 1,
    }));
  };

  const setDate = (value, typeValue) => {
    if (typeValue === "DEPARTURE") {
      setDepartureDate(value);

      if (
        returnDate < value ||
        (router.query.typeSearch === "hotels" && returnDate === value)
      ) {
        if (router.query.typeSearch === "hotels") {
          const returnDateObj = new Date(value);
          returnDateObj.setDate(returnDateObj.getDate() + 1);
          const returnDateString = `${returnDateObj.getFullYear()}-${pad2(
            returnDateObj.getMonth() + 1
          )}-${returnDateObj.getDate()}`;

          setReturnDate(returnDateString);
        } else {
          setReturnDate(value);
        }
      }
    } else {
      setReturnDate(value);
    }
  };

  const setMultiCityDate = (value: string, index: number): void => {
    const updatedSearchFlights = searchFlights.map((el, i) =>
      i === index ? { ...el, departureDate: value } : el
    );

    for (let i = 0; i < updatedSearchFlights.length - 1; i++) {
      if (
        updatedSearchFlights[i].departureDate >
        updatedSearchFlights[i + 1].departureDate
      ) {
        updatedSearchFlights[i + 1].departureDate =
          updatedSearchFlights[i].departureDate;
      }
    }

    setSearchFlights(updatedSearchFlights);
  };

  const onChangeFlightClass = (value: string) => setFlightClass(value);

  const onChangeExtendedWeekends = () => {
    setWeekends(!weekends);
  };

  const onDirectFlightChange = () => {
    setDirectFlight(!directFlight);
  };

  const selectAirline = (val) => {
    setSelectedTransportCompany(val.value);
    setAirlinesTouched(val.value !== "all");
  };

  const addFlight = () => {
    if (searchFlights.length > 7) {
      return false;
    }
    setSearchFlights((prevSearchFlights) => [
      ...prevSearchFlights,
      {
        ...searchFlightBase,
        departureDate:
          prevSearchFlights[prevSearchFlights.length - 1].departureDate,
      },
    ]);
    return true;
  };

  const removeFlight = (indexSearchFlight) => {
    if (searchFlights.length <= 1) {
      return false;
    }
    setSearchFlights((prevSearchFlights) =>
      prevSearchFlights.filter((o, i) => i !== indexSearchFlight)
    );
    return true;
  };

  const { intl } = props;
  const isHotelSearchForm = checkIsHotelSearchForm(router);
  const shouldMinimizeSearchFormResolved =
    shouldMinimizeSearchForm &&
    (to || searchFlights[0].destination) &&
    !EXPORT_HTML_PACKAGE &&
    !["/iframe", "/"].includes(router.pathname);
  const blockSearchDaysBefore =
    parseInt(
      frontendSettings?.dealerFlightSearchSettings?.blockSearchDaysBefore,
      10
    ) || 0;

  const hotelModuleEnabled = module?.includes(MODULES.HOTEL);
  const flightsModuleEnabled = module?.includes(MODULES.AIR);

  if (router.route === "/register" && !anonymousSearchEnabled) {
    return null;
  }

  const counterSignPlusClass = classnames("counter-sign", {
    "counter-sign-disabled": searchFlights.length > 7,
  });

  const onMinimizedHeaderSearchFormButtonClick = () =>
    dispatch(setShouldMinimizeSearchForm(false));

  const onMinimizedHeaderSearchFormToLinkClick = () => {
    dispatch(setShouldMinimizeSearchForm(false));
    setTimeout(() => {
      if (typeSearch === "MULTIPLE") {
        if (searchFlights.length > 0) {
          const newSearchFlights = searchFlights.slice();
          newSearchFlights[0] = { ...newSearchFlights[0], destination: "" };

          setSearchFlights(newSearchFlights);
        }
      } else {
        setTo("");
      }
      inputToRef.current.focus();
    }, 0);
  };

  const onMinimizedHeaderSearchFormFromLinkClick = () => {
    dispatch(setShouldMinimizeSearchForm(false));
    setTimeout(() => {
      if (typeSearch === "MULTIPLE") {
        if (searchFlights.length > 0) {
          const newSearchFlights = searchFlights.slice();
          newSearchFlights[0] = { ...newSearchFlights[0], origin: "" };

          setSearchFlights(newSearchFlights);
        }
      } else {
        setFrom("");
      }
      inputFromRef.current.focus();
    }, 0);
  };

  const totalPassengersCount: () => number = () =>
    isLoggedIn && isCbtEnabled
      ? cbtPassengersForm.travellers.length + cbtPassengersForm.guests
      : countPassengers(passengers);

  return (
    <div
      className={classnames("header-search-form", {
        iframe: isIframe(router.route),
      })}
    >
      {(!shouldMinimizeSearchFormResolved ||
        EXPORT_HTML_PACKAGE ||
        isIframe(props.router?.route)) && (
        <>
          <div
            className={classnames("header-search-form-tabs", {
              "header-search-form-tabs-with-hotels": hotelsEnabled,
            })}
          >
            {(!router.query.typeSearch ||
              router.query.typeSearch === "flights") &&
              flightsModuleEnabled && (
                <div className="header-search-form-tab-flight-container">
                  <div
                    className={classnames("header-search-form-tab", {
                      "header-search-form-tab--active":
                        typeSearch === "ONE_WAY",
                      iframe: isIframe(router?.route),
                    })}
                    id="header-search-form-tab_ONE_WAY"
                    onClick={() => changeTypeSearch("ONE_WAY")}
                    role="button"
                  >
                    <Img
                      src="/static/images/ico-plane-oneway.svg"
                      className={classnames("header-search-form-tab-ico", {
                        iframe: isIframe(router?.route),
                      })}
                      height={15}
                      width={29}
                      alt="plane icon"
                    />

                    <span
                      className={classnames("header-search-form-tab-text", {
                        "header-search-form-tab-text-active":
                          typeSearch === "ONE_WAY",
                        iframe: isIframe(router?.route),
                      })}
                      id="GOL_package-textStorage-SearchForm.oneWay"
                    >
                      <FormattedMessage id="SearchForm.oneWay" />
                    </span>
                  </div>
                  <div
                    className={classnames("header-search-form-tab", {
                      "header-search-form-tab--active": typeSearch === "RETURN",
                      iframe: isIframe(router?.route),
                    })}
                    id="header-search-form-tab_RETURN"
                    onClick={() => changeTypeSearch("RETURN")}
                    role="button"
                  >
                    <Img
                      src="/static/images/ico-plane-back.svg"
                      className={classnames(
                        "header-search-form-tab-ico-return",
                        {
                          iframe: isIframe(router?.route),
                        }
                      )}
                      height={22}
                      width={22}
                      alt="plane icon"
                    />

                    <span
                      className={classnames("header-search-form-tab-text", {
                        iframe: isIframe(router?.route),
                      })}
                      id="GOL_package-textStorage-SearchForm.return"
                    >
                      <FormattedMessage id="SearchForm.return" />
                    </span>
                  </div>
                  <div
                    role="button"
                    className={classnames(
                      "header-search-form-tab",
                      { "header-search-form-tab-last": !hotelsEnabled },
                      {
                        "header-search-form-tab--active":
                          typeSearch === "MULTIPLE",
                        iframe: isIframe(router?.route),
                      }
                    )}
                    id="header-search-form-tab_MULTIPLE"
                    onClick={() => changeTypeSearch("MULTIPLE")}
                  >
                    <Img
                      src="/static/images/ico-plane-double.svg"
                      className={classnames("header-search-form-tab-ico", {
                        iframe: isIframe(router?.route),
                      })}
                      height={15}
                      width={36}
                      alt="plane icon"
                    />
                    <span
                      className={classnames("header-search-form-tab-text", {
                        iframe: isIframe(router?.route),
                      })}
                      id="GOL_package-textStorage-SearchForm.multiCity"
                    >
                      <FormattedMessage id="SearchForm.multiCity" />
                    </span>
                  </div>
                </div>
              )}

            {flightsModuleEnabled && router.query?.typeSearch === "hotels" && (
              <div
                className={classnames("header-search-form-tab", {
                  iframe: isIframe(router?.route),
                })}
                id="header-search-form-tab_RETURN"
                onClick={() => changeTypeSearch("FLIGHTS")}
                role="button"
              >
                <Img
                  src="/static/images/ico-plane-oneway.svg"
                  className={classnames("header-search-form-tab-ico", {
                    iframe: isIframe(router?.route),
                  })}
                  height={15}
                  width={29}
                  alt="plane icon"
                />

                <span
                  className={classnames("header-search-form-tab-text", {
                    iframe: isIframe(router?.route),
                  })}
                >
                  <FormattedMessage id="SearchForm.flightTickets" />
                </span>
              </div>
            )}
            {hotelsEnabled && hotelModuleEnabled && (
              <div
                className={classnames(
                  "header-search-form-tab header-search-form-tab--change-type-search header-search-form-tab-hotels",
                  {
                    "header-search-form-tab--active":
                      router.query.typeSearch === "hotels",
                    iframe: isIframe(router?.route),
                  }
                )}
                role="button"
                onClick={() => changeTypeSearch("HOTELS")}
              >
                <Img
                  src="/static/images/ico-hotel.svg"
                  className={classnames("header-search-form-tab-ico", {
                    iframe: isIframe(router?.route),
                  })}
                  style={{ height: 16 }}
                  alt="hotel icon"
                />
                <span
                  className={classnames("header-search-form-tab-text", {
                    iframe: isIframe(router?.route),
                  })}
                >
                  <FormattedMessage id="SearchForm.hotels" />
                </span>
              </div>
            )}
          </div>
          <div className="header-search-form-inner">
            {(["ONE_WAY", "RETURN"].includes(typeSearch) ||
              isHotelSearchForm) && (
              <div
                className="header-search-form-inner-cols"
                id="searchForm-standard"
              >
                <div className="header-search-form-inner-first-col">
                  <div
                    className={classnames("header-search-form-desktop-line", {
                      iframe: isIframe(router?.route),
                    })}
                  >
                    <div
                      className={classnames("header-search-form-inner-field", {
                        iframe: isIframe(router?.route),
                      })}
                    >
                      <span className="header-search-form-inner-field-label">
                        {" "}
                        {isHotelSearchForm ? (
                          <FormattedMessage id="SearchForm.hotels.destinationLabel" />
                        ) : (
                          <span id="GOL_package-textStorage-SearchForm.from">
                            <FormattedMessage id="SearchForm.from" />
                          </span>
                        )}
                      </span>
                      {isHotelSearchForm ? (
                        <SearchFormHotelSelect
                          intl={intl}
                          testId="to-hotel"
                          inputRef={inputToRef}
                          defaultValue={to as AirportSelectValue}
                          changeValue={(e) => {
                            setTo(e === null ? "" : e);
                            setError({ ...error, to: false });
                          }}
                          idJumToAfterTab="to"
                          error={error.to}
                        />
                      ) : (
                        <SearchFormAirportSelect
                          intl={intl}
                          testId="from"
                          inputRef={inputFromRef}
                          defaultValue={from as AirportSelectValue}
                          allowDefaultOptions
                          changeValue={(e) => {
                            setFrom(e === null ? "" : e);
                            setError({ ...error, from: false });
                          }}
                          idJumToAfterTab="to"
                          error={error.from}
                        />
                      )}
                    </div>
                    {!isHotelSearchForm && (
                      <div
                        className={classnames(
                          "header-search-form-inner-field ",
                          {
                            iframe: isIframe(router?.route),
                          }
                        )}
                      >
                        <div className="header-search-form-inner-field-label">
                          <span id="GOL_package-textStorage-SearchForm.to">
                            <FormattedMessage id="SearchForm.to" />
                          </span>
                          {typeSearch === "RETURN" ? (
                            <div
                              id="header-search-form-multiCity-redirect-container"
                              style={{ display: "inline-block" }}
                            >
                              <span className="header-search-form-link-divider">
                                |
                              </span>
                              <span
                                id="header-search-form-multiCity-redirect"
                                onClick={() => changeTypeSearch("MULTIPLE")}
                                className="header-search-form-link-text"
                                role="button"
                              >
                                <span id="GOL_package-textStorage-SearchForm.differentReturnAirport">
                                  <FormattedMessage id="SearchForm.differentReturnAirport" />
                                </span>
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <SearchFormAirportSelect
                          intl={intl}
                          testId="to"
                          inputRef={inputToRef}
                          defaultValue={to as AirportSelectValue}
                          allowDefaultOptions={false}
                          changeValue={(e) => {
                            setTo(e === null ? "" : e);
                            setError({ ...error, to: false });
                          }}
                          error={error.to}
                        />
                      </div>
                    )}
                  </div>
                  <DatesRow
                    flightTypeSearch={typeSearch}
                    departureDate={departureDate}
                    returnDate={returnDate}
                    setDate={setDate}
                    blockSearchDaysBefore={blockSearchDaysBefore}
                  />
                  <div className="clearfix" />
                </div>
                <PassengerPreferences
                  user={user}
                  frontendSettings={frontendSettings}
                  route={router.route}
                  error={error.cbtPassengersForm}
                  flightClass={flightClass}
                  minusCounterHandler={minusCounterHandler}
                  plusCounterHandler={plusCounterHandler}
                  onChangeFlightClass={onChangeFlightClass}
                  passengers={passengers}
                  rooms={rooms}
                  resetPassengers={() =>
                    setPassengers(
                      getInitialPassengersNumbersFromAllowedTravelerTypes()
                    )
                  }
                />
              </div>
            )}
            {(typeSearch === "MULTIPLE" || EXPORT_HTML_PACKAGE) &&
              !isHotelSearchForm && (
                <div id="searchForm-multiCity">
                  {searchFlights.map((oSearchFlight, indexSearchFlight) => (
                    <div
                      id={`searchForm-multiCity-row-${indexSearchFlight}`}
                      key={indexSearchFlight}
                      className={classnames("header-search-form-desktop-line", {
                        iframe: isIframe(router?.route),
                      })}
                    >
                      <div
                        className={classnames(
                          "header-search-form-inner-field header-search-form-desktop-line-one",
                          {
                            iframe: isIframe(router?.route),
                          }
                        )}
                      >
                        <span className="header-search-form-inner-field-label GOL_package-textStorage-SearchForm.from">
                          {" "}
                          <FormattedMessage id="SearchForm.from" />
                        </span>
                        <SearchFormAirportSelect
                          intl={intl}
                          inputRef={
                            indexSearchFlight === 0 ? inputFromRef : null
                          }
                          testId={`from-${indexSearchFlight}`}
                          idJumToAfterTab={`to-${indexSearchFlight}`}
                          defaultValue={
                            searchFlights[indexSearchFlight] !== undefined
                              ? (searchFlights[indexSearchFlight]
                                  .origin as AirportSelectValue)
                              : ""
                          }
                          allowDefaultOptions={indexSearchFlight === 0}
                          changeValue={(value) => {
                            const copySearchFlights = [...searchFlights];
                            copySearchFlights[indexSearchFlight].origin = value;
                            const errorAttr = `flight_${
                              indexSearchFlight + 1
                            }_origin`;
                            const updatedError = {
                              ...error,
                              multiCity: { ...error.multiCity },
                            };
                            delete updatedError.multiCity[errorAttr];
                            setSearchFlights(copySearchFlights);
                            setError({ ...updatedError });
                          }}
                          error={
                            error.multiCity[
                              `flight_${indexSearchFlight + 1}_origin`
                            ]
                          }
                        />
                      </div>
                      <div
                        className={classnames(
                          "header-search-form-inner-field header-search-form-desktop-line-one",
                          {
                            iframe: isIframe(router?.route),
                          }
                        )}
                      >
                        <span className="header-search-form-inner-field-label GOL_package-textStorage-SearchForm.to">
                          <FormattedMessage id="SearchForm.to" />
                        </span>
                        <SearchFormAirportSelect
                          intl={intl}
                          testId={`to-${indexSearchFlight}`}
                          inputRef={indexSearchFlight === 0 ? inputToRef : null}
                          defaultValue={
                            searchFlights[indexSearchFlight] !== undefined
                              ? (searchFlights[indexSearchFlight]
                                  .destination as AirportSelectValue)
                              : ""
                          }
                          allowDefaultOptions={false}
                          changeValue={(value) => {
                            const copySearchFlights = [...searchFlights];
                            copySearchFlights[
                              indexSearchFlight
                            ].destination = value;
                            const errorAttr = `flight_${
                              indexSearchFlight + 1
                            }_destination`;
                            const updatedError = {
                              ...error,
                              multiCity: { ...error.multiCity },
                            };
                            delete updatedError.multiCity[errorAttr];
                            setSearchFlights(copySearchFlights);
                            setError({ ...updatedError });
                          }}
                          error={
                            error.multiCity[
                              `flight_${indexSearchFlight + 1}_destination`
                            ]
                          }
                        />
                      </div>
                      <div
                        className={classnames(
                          "header-search-form-desktop-line-one",
                          {
                            iframe: isIframe(router?.route),
                          }
                        )}
                      >
                        <div className="header-search-form-inner-field">
                          <span className="header-search-form-inner-field-label GOL_package-textStorage-SearchForm.departureDate">
                            <FormattedMessage id="SearchForm.departureDate" />
                          </span>
                          <DatePicker
                            typeSearch="DEPARTURE"
                            testId={`departure_date_${indexSearchFlight}`}
                            date={oSearchFlight.departureDate}
                            onChange={(value) =>
                              setMultiCityDate(value, indexSearchFlight)
                            }
                            disabledTillDate={
                              searchFlights[indexSearchFlight - 1] !== undefined
                                ? new Date(
                                    searchFlights[
                                      indexSearchFlight - 1
                                    ].departureDate
                                  )
                                : moment()
                                    .add(blockSearchDaysBefore, "days")
                                    .toDate()
                            }
                          />
                        </div>
                      </div>
                      <div className="header-search-form-multiple-add">
                        {indexSearchFlight !== 0 && (
                          <div
                            role="button"
                            onClick={() => removeFlight(indexSearchFlight)}
                            className="header-search-form-multiple-add-inner-container-minus"
                          >
                            <Img
                              className="header-search-form-multiple-add-inner-image"
                              src="/static/images/ico-minus-gray.svg"
                              id={`searchForm-multiCity-button-remove-${indexSearchFlight}`}
                              alt="minus icon"
                            />
                            <span
                              id={`GOL_package-textStorage-SearchForm.remove-${indexSearchFlight}`}
                              className="header-search-form-multiple-add-minus-button-label GOL_package-textStorage-SearchForm.remove"
                            >
                              <FormattedMessage id="SearchForm.remove" />
                            </span>
                          </div>
                        )}
                        {indexSearchFlight === searchFlights.length - 1 && (
                          <div
                            role="button"
                            onClick={addFlight}
                            className="header-search-form-multiple-add-inner-container-plus"
                          >
                            <Img
                              className={`${counterSignPlusClass} header-search-form-multiple-add-inner-image`}
                              src="/static/images/ico-plus.svg"
                              id={`searchForm-multiCity-button-add-${indexSearchFlight}`}
                              alt="plus icon"
                            />
                            <span
                              id={`GOL_package-textStorage-SearchForm.anotherFlight-${indexSearchFlight}`}
                              className="header-search-form-multiple-add-plus-button-label GOL_package-textStorage-SearchForm.anotherFlight"
                            >
                              <FormattedMessage id="SearchForm.anotherFlight" />
                            </span>
                          </div>
                        )}
                      </div>
                      {indexSearchFlight !== searchFlights.length - 1 && (
                        <div className="header-search-form-multiple-divider" />
                      )}
                    </div>
                  ))}
                  <PassengerPreferences
                    multi
                    user={user}
                    frontendSettings={frontendSettings}
                    route={router.route}
                    resetPassengers={() =>
                      setPassengers(
                        getInitialPassengersNumbersFromAllowedTravelerTypes()
                      )
                    }
                    error={error.cbtPassengersForm}
                    flightClass={flightClass}
                    minusCounterHandler={minusCounterHandler}
                    plusCounterHandler={plusCounterHandler}
                    onChangeFlightClass={onChangeFlightClass}
                    passengers={passengers}
                    rooms={rooms}
                  />
                </div>
              )}
          </div>
          <FilterBar
            intl={intl}
            selectAirlines={selectAirline}
            selectedTransportCompany={selectedTransportCompany}
            search={search}
            toleranceDays={toleranceDays}
            increaseTolerance={increaseTolerance}
            decreaseTolerance={decreaseTolerance}
            weekends={weekends}
            onDirectFlightChange={onDirectFlightChange}
            extendedWeekend={onChangeExtendedWeekends}
            variableDays={
              !isHotelSearchForm &&
              typeSearch !== "MULTIPLE" &&
              plusMinusDaysEnabled(frontendSettings)
            }
            directFlights={directFlight}
            airlinesTouched={airlinesTouched}
          />
          <FilterBarMobile
            intl={intl}
            selectAirlines={selectAirline}
            selectedTransportCompany={selectedTransportCompany}
            search={search}
            toleranceDays={toleranceDays}
            increaseTolerance={increaseTolerance}
            decreaseTolerance={decreaseTolerance}
            weekends={weekends}
            onDirectFlightChange={onDirectFlightChange}
            extendedWeekend={onChangeExtendedWeekends}
            variableDays={
              !isHotelSearchForm &&
              typeSearch !== "MULTIPLE" &&
              plusMinusDaysEnabled(frontendSettings)
            }
            directFlights={directFlight}
            airlinesTouched={airlinesTouched}
          />
        </>
      )}

      {shouldMinimizeSearchFormResolved && (
        <MinimizedHeaderSearchForm
          searchFlights={searchFlights}
          from={from as AirportSelectValue}
          to={to as AirportSelectValue}
          departureDate={departureDate}
          returnDate={returnDate}
          currentLanguage={currentLanguage}
          defaultCountry={defaultCountry}
          passengersCount={totalPassengersCount()}
          flightClass={flightClass}
          onButtonClick={onMinimizedHeaderSearchFormButtonClick}
          intl={intl}
          typeSearch={isHotelSearchForm ? "HOTELS" : typeSearch}
          onToLinkClick={onMinimizedHeaderSearchFormToLinkClick}
          onFromLinkClick={onMinimizedHeaderSearchFormFromLinkClick}
        />
      )}
    </div>
  );
};

export default withRouter(injectIntl(HeaderSearchForm));
